import React from "react";
import { Paper, PaperProps } from "@mui/material";
import styled from "styled-components";
import { Loader } from "./Loader";

export const StyledPaper = styled((props: PaperProps) => {
  return (
    <Paper {...props}>
      <Loader>{props.children}</Loader>
    </Paper>
  );
})`
  margin-bottom: 1rem;
  borderradius: 7px;
  boxshadow: none;
  width: 100%;
  max-width: calc(100vw - 64px);

  @media (min-width: 882px) {
    max-width: calc(100vw - 340px);
  }
`;
